import React from "react"
import Helmet from "react-helmet"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from "reactstrap"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import "../style/main.scss"
import Logo from "../images/logo-dark-2x.png"
import LogoWhite from "../images/logo-light-2x.png"


export default class Layout extends React.Component {
  state = {
    isOpen: false,
  }

  toggle = () => this.setState({
    isOpen: !this.state.isOpen,
  })

  componentDidMount() {
    if(window.location.hash !== ''){
      let hash = window.location.hash;
      window.location.hash = '';
      window.location.hash = hash;
    }
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <link href="https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap" rel="stylesheet"/>
          <link href="https://fonts.googleapis.com/css?family=Anton&display=swap" rel="stylesheet"/>
        </Helmet>
        <div className={"container-fluid p-xl-5"}>
          <header className={"mb-3"}>
            <div className={"row mb-3 bg-secondary"}>
              <div className={"col text-center text-md-right text-primary text-light py-3 font-weight-bold"}>
                <span className={"d-none d-md-inline"}>CALL US: M - F 9am-6pm PST </span> <a href="tel:800-633-4374" style={{ textDecoration: "underline" }}>+ 1 (800) 633 4374</a>
              </div>
            </div>
            <Navbar expand="lg" color={"faded"} light={true}>
              <Link to={"/"} className={"navbar-brand"}>
                <img src={Logo} alt={"Powersports Support Logo"} width={200} height={"auto"}/>
              </Link>
              <NavbarToggler onClick={this.toggle}/>
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav navbar className={"w-100 nav-fill"}>
                  <NavItem>
                    <Link to={"/integrated-marketplaces/"} className={"font-weight-bold text-uppercase nav-link text-primary"}>Integrated <span className={"d-block"}>Marketplaces</span></Link>
                  </NavItem>
                  <NavItem>
                    <Link to={"/distribution-partners/"} className={"font-weight-bold text-uppercase nav-link text-primary"}>Distribution <span className={"d-block"}>Partners</span></Link>
                  </NavItem>
                  <NavItem>
                    <Link to={"/ecommerce-tools/"} className={"font-weight-bold text-uppercase nav-link text-primary"}>eCommerce <span className={"d-block"}>Tools</span></Link>
                  </NavItem>
                  <NavItem className={"d-flex"}>
                    <Link to='/demo-request' className={"btn btn-lg btn-primary w-100 align-self-center"} style={{ backgroundColor: "#08354f" }}>
                      <span
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "200",
                          letterSpacing: ".1em",
                          fontSize: ".85rem",
                        }}
                      >
                        Request Demo
                      </span>
                    </Link>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          </header>
          <main className={"row"}>
            <div className={"col"}>
              {this.props.children}
            </div>
          </main>
          <footer className={"bg-primary p-5 row text-center"} style={{ color: "rgba(255,255,255,0.605)" }}>
            <div className={"col-lg-4 mb-5"}>
              <div className={"row"}>
                <div className={"col-12 mb-3"}>
                  <Link to={"/"}>
                    <img src={LogoWhite} alt={"Powersports Support Logo"} className={"img-fluid"}/>
                  </Link>
                </div>
                <div className={"col-12 mb-3"}>
                  <p>Powersports Support combines integrated Software as a Service (SaaS) and industry leading experience and strategies to grow your online sales while streamlining the entire process. We're here for your success.</p>
                </div>
                <div className={"col-12"}>
                  <address>
                    <strong>Powersports Support, LLC.</strong><br/>
                    1381 Warner Ave Tustin, CA 92780<br/>
                    M-F 9am-6pm PST<br/>
                    +1 (800) 633-4374
                  </address>
                </div>
              </div>
            </div>
            <div className={"col-12 col-md-6 col-lg mb-3"}>
              <h5 className={"text-capitalize text-white mb-lg-5"}>RESOURCES</h5>
              <p>
                <Link to={"/pricing/"}>Pricing</Link>
              </p>
              <p>
                <Link to={"/support/"}>Support</Link>
              </p>
            </div>
            <div className={"col-12 col-md-6 col-lg mb-3"}>
              <h5 className={"text-capitalize text-white mb-lg-5"}>ABOUT US</h5>
              <p>
                <Link to={"/our-story/"}>Our Story</Link>
              </p>
              <p>
                <Link to={"/product-tour/"}>Why PSS?</Link>
              </p>
              <p>
                <Link to={"/distribution-partners/"}>Partnerships</Link>
              </p>
            </div>
            <div className={"col-12 col-md-6 col-lg mb-3"}>
              <h5 className={"text-capitalize text-white mb-lg-5"}>SUPPORT</h5>
              <p>
                <OutboundLink href={"https://help.powersportsupport.com/"} target={"_blank"}>Knowledge Base</OutboundLink>
              </p>
              <p>
                <Link to={"/contact-us"} target={"_blank"}>Contact Us</Link>
              </p>
            </div>
            <div className={"col-12 col-md-6 col-lg mb-3"}>
              <h5 className={"text-capitalize text-white mb-lg-5"}>CONNECT</h5>
              <p>
                <OutboundLink href={"https://youtu.be/78QORA9AkXQ"} target={"_blank"} rel={"nofollow noopener"}>Youtube</OutboundLink>
              </p>
              <p>
                <OutboundLink href={"https://www.facebook.com/powersportsupport"} target={"_blank"} rel={"nofollow noopener"}>Facebook</OutboundLink>
              </p>
              <p>
                <OutboundLink href={"https://www.instagram.com/powersportsupport/"} target={"_blank"} rel={"nofollow noopener"}>Instagram</OutboundLink>
              </p>
              <p>
                <OutboundLink href={"https://www.linkedin.com/company/powersports-support/"} target={"_blank"} rel={"nofollow noopener"}>LinkedIn</OutboundLink>
              </p>
            </div>
            <div className={"col-12 text-center mt-5"}>
              Copyright © 2013 - {new Date().getFullYear()}
              <span className={"d-block d-md-inline"}> Powersports Support LLC.</span>
              <span className={"d-block d-lg-inline"}> All rights reserved.</span>
            </div>
          </footer>
        </div>
      </React.Fragment>
    )
  }
}
